import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 150px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  @media only screen and (width < 1200px) {
    height: 200px;
  }
`;

export const MobileHeaderContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

export const GridContainer = styled(motion.div)`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const NavTitle = styled.div`
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  justify-content: center;

  @media only screen and (width < 1800px) {
    font-size: 14px;
  }
`;

export const LanguageContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CenteredGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const MenuButtonHolder = styled(motion.div)`
  color: white;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const DrawerContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const DrawerItem = styled(motion.div)`
  width: 100%;
  height: auto;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const DrawerTitle = styled.div`
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
`;

export const DrawerDot = styled.div`
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  margin-right: 1rem;
`;
