import { Menu } from '@mui/icons-material';
import { Drawer, Grid } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import {
  CenteredGrid,
  DrawerContainer,
  DrawerDot,
  DrawerItem,
  DrawerTitle,
  GridContainer,
  HeaderContainer,
  LanguageContainer,
  MenuButtonHolder,
  MobileHeaderContainer,
  NavTitle,
} from './styles';

interface HomeHeaderProps {
  handleScrollOrganizer: () => void
  handleScrollVisitor: () => void
  handleScrollAccess: () => void
  handleScrollUseCase: () => void
  handleScrollFAQ: () => void
}

function HomeHeader(props: HomeHeaderProps) {
  const {
    handleScrollOrganizer,
    handleScrollVisitor,
    handleScrollAccess,
    handleScrollUseCase,
    handleScrollFAQ,
  } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const onNavClick = (section: string) => {
    setDrawerOpen(false);
    switch (section) {
      case 'organizer':
        handleScrollOrganizer();
        break;
      case 'visitor':
        handleScrollVisitor();
        break;
      case 'access':
        handleScrollAccess();
        break;
      case 'useCase':
        handleScrollUseCase();
        break;
      case 'signup_login':
        window.open('https://manager.movon.vip', '_blank');
        break;
      default:
        handleScrollFAQ();
        break;
    }
  };

  if (isTabletOrMobile) {
    return (
      <>
        <MobileHeaderContainer>
          <Grid container>
            <Grid item xs={4} />
            <CenteredGrid item xs={4}>
              <LanguageContainer>
                <LanguageSelector />
              </LanguageContainer>
            </CenteredGrid>
            <CenteredGrid item xs={4}>
              <MenuButtonHolder
                whileTap={{ scale: 0.9, opacity: 0.9 }}
                onClick={() => setDrawerOpen(true)}
              >
                <Menu />
              </MenuButtonHolder>
            </CenteredGrid>
          </Grid>
        </MobileHeaderContainer>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <DrawerContainer>
            <DrawerItem
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              onClick={() => onNavClick('organizer')}
            >
              <DrawerDot />
              <DrawerTitle>
                <FormattedMessage id="home_nav_organizers" />
              </DrawerTitle>
            </DrawerItem>
            <DrawerItem
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              onClick={() => onNavClick('visitor')}
            >
              <DrawerDot />
              <DrawerTitle>
                <FormattedMessage id="home_nav_visitors" />
              </DrawerTitle>
            </DrawerItem>
            <DrawerItem
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              onClick={() => onNavClick('access')}
            >
              <DrawerDot />
              <DrawerTitle>
                <FormattedMessage id="home_nav_about" />
              </DrawerTitle>
            </DrawerItem>
            <DrawerItem
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              onClick={() => onNavClick('useCase')}
            >
              <DrawerDot />
              <DrawerTitle>
                <FormattedMessage id="home_nav_use_cases" />
              </DrawerTitle>
            </DrawerItem>
            <DrawerItem
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              onClick={() => onNavClick('faq')}
            >
              <DrawerDot />
              <DrawerTitle>
                <FormattedMessage id="home_nav_faq" />
              </DrawerTitle>
            </DrawerItem>
            <DrawerItem
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              onClick={() => onNavClick('signup_login')}
            >
              <DrawerDot />
              <DrawerTitle>
                <FormattedMessage id="home_nav_sign_up_login" />
              </DrawerTitle>
            </DrawerItem>
          </DrawerContainer>
        </Drawer>
      </>
    );
  }

  return (
    <HeaderContainer>
      <Grid container rowGap={2} justifyContent="space-around">
        <Grid item>
          <GridContainer
            whileTap={{ scale: 0.95, opacity: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={handleScrollOrganizer}
          >
            <NavTitle>
              <FormattedMessage id="home_nav_organizers" />
            </NavTitle>
          </GridContainer>
        </Grid>
        <Grid item>
          <GridContainer
            whileTap={{ scale: 0.95, opacity: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={handleScrollVisitor}
          >
            <NavTitle>
              <FormattedMessage id="home_nav_visitors" />
            </NavTitle>
          </GridContainer>
        </Grid>
        <Grid item>
          <GridContainer
            whileTap={{ scale: 0.95, opacity: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={handleScrollAccess}
          >
            <NavTitle>
              <FormattedMessage id="home_nav_about" />
            </NavTitle>
          </GridContainer>
        </Grid>
        <Grid item>
          <GridContainer
            whileTap={{ scale: 0.95, opacity: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={handleScrollUseCase}
          >
            <NavTitle>
              <FormattedMessage id="home_nav_use_cases" />
            </NavTitle>
          </GridContainer>
        </Grid>
        <Grid item>
          <GridContainer
            whileTap={{ scale: 0.95, opacity: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={handleScrollFAQ}
          >
            <NavTitle>
              <FormattedMessage id="home_nav_faq" />
            </NavTitle>
          </GridContainer>
        </Grid>
        <Grid item>
          <GridContainer
            whileTap={{ scale: 0.95, opacity: 0.9 }}
            whileHover={{ scale: 1.05 }}
            onClick={() => window.open('https://manager.movon.vip', '_blank')}
          >
            <NavTitle>
              <FormattedMessage id="home_nav_sign_up_login" />
            </NavTitle>
          </GridContainer>
        </Grid>
        <CenteredGrid item>
          <LanguageContainer>
            <LanguageSelector />
          </LanguageContainer>
        </CenteredGrid>
      </Grid>
    </HeaderContainer>
  );
}

export default HomeHeader;
