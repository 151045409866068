import IntroBkg from 'assets/images/home/bkg_header_mobile.jpg';
import OrganizerBkg from 'assets/images/home/bkg_section2.jpg';
import styled from 'styled-components';

interface FirstSectionProps {
  $url: string
}

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const FirstSectionContainer = styled.div<FirstSectionProps>`
  position: relative;
  width: 100%;
  height: 109vh;
  align-items: center;
  background-image: url(${(props) => props.$url});
  background-size: cover;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    background-image: url(${IntroBkg});
  }
`;

export const LogoContainer = styled.img`
  width: 60%;
  height: auto;
  margin: auto;
  display: flex;
  margin-top: 25px;
`;

export const CenterNav = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const BlurBackgroundSection = styled.div`
  width: 100%;
  height: auto;
  background-image: url(${OrganizerBkg});
  background-size: cover;
`;
